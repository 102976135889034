import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  FileText, Activity, Users, Settings, Video, Mail, 
  BarChart2, Target, Brain, Calendar, ArrowRight,
  CheckCircle, AlertCircle, Tool, Clock, RefreshCcw,
  Database, ArrowDown, Zap
} from 'lucide-react';

const ProcessStep = ({ step, isActive, onClick, description, icon: Icon }) => (
  <motion.div
    className={`flex items-center gap-4 p-6 rounded-xl border-2 transition-all cursor-pointer
      ${isActive ? 'border-blue-500 bg-blue-50' : 'border-gray-200 hover:border-blue-200'}`}
    initial={{ opacity: 0, x: -20 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ duration: 0.5 }}
    onClick={onClick}
    whileHover={{ scale: 1.02 }}
  >
    <div className={`p-3 rounded-full ${isActive ? 'bg-blue-500' : 'bg-gray-200'}`}>
      {Icon && <Icon className={`w-6 h-6 ${isActive ? 'text-white' : 'text-gray-500'}`} />}
    </div>
    <div className="flex-1">
      <h3 className="font-bold text-lg">{step}</h3>
      <p className="text-sm text-gray-600 mt-1">{description}</p>
    </div>
    <ArrowRight className={`w-5 h-5 transform transition-all ${isActive ? 'translate-x-1 text-blue-500' : 'text-gray-400'}`} />
  </motion.div>
);

const StepDetails = ({ step }) => {
  const details = workflowSteps[step];
  if (!details) return null;

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={step}
        className="bg-white p-8 rounded-xl shadow-lg border border-gray-100"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.5 }}
      >
        <div className="flex items-center gap-3 mb-6">
          {details.icon && <details.icon className="w-8 h-8 text-blue-500" />}
          <h3 className="text-2xl font-bold">{details.title}</h3>
        </div>
        
        <div className="space-y-6">
          {details.content.map((item, index) => (
            <div key={index} className="bg-gray-50 p-6 rounded-lg">
              <h4 className="font-semibold text-blue-600 text-lg mb-4">{item.subtitle}</h4>
              <ul className="space-y-3">
                {item.points.map((point, i) => (
                  <li key={i} className="flex items-start gap-2">
                    <CheckCircle className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <span className="text-gray-700">{point}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {details.metrics && (
          <div className="mt-6 grid grid-cols-3 gap-4">
            {details.metrics.map((metric, index) => (
              <div key={index} className="bg-blue-50 p-4 rounded-lg text-center">
                <div className="text-2xl font-bold text-blue-600">{metric.value}</div>
                <div className="text-sm text-gray-600">{metric.label}</div>
              </div>
            ))}
          </div>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

const workflowSteps = {
  acquisition: {
    title: "Lead Acquisition",
    icon: FileText,
    description: "Multi-channel lead capture with attribution tracking",
    content: [
      {
        subtitle: "Entry Channels",
        points: [
          "Website forms with optimized conversion fields",
          "Direct marketplace directory integration",
          "Partner referral system with attribution",
          "Gated premium content downloads"
        ]
      },
      {
        subtitle: "Data Collection",
        points: [
          "Company size and revenue validation",
          "Current status verification",
          "Decision maker role identification",
          "Product category assessment"
        ]
      }
    ],
    metrics: [
      { value: "4+", label: "Lead Sources" },
      { value: "98%", label: "Data Accuracy" },
      { value: "2min", label: "Avg. Form Time" }
    ]
  },
  processing: {
    title: "Initial Processing",
    icon: Activity,
    description: "Data validation and enrichment process",
    content: [
      {
        subtitle: "Data Validation",
        points: [
          "Automated completeness check",
          "Format and accuracy verification",
          "Duplicate detection",
          "Data standardization"
        ]
      },
      {
        subtitle: "Enrichment Process",
        points: [
          "Company data augmentation",
          "Revenue verification",
          "Employee count validation",
          "Industry classification"
        ]
      }
    ],
    metrics: [
      { value: "99.9%", label: "Accuracy Rate" },
      { value: "<1min", label: "Processing Time" },
      { value: "85%", label: "Enrichment Success" }
    ]
  },
  segmentation: {
    title: "Segmentation Process",
    icon: Users,
    description: "Revenue-based routing and track assignment",
    content: [
      {
        subtitle: "Revenue Segments",
        points: [
          "New Business ($0-1M): Basic education focus",
          "Growing Business ($1M-10M): Optimization focus",
          "Enterprise ($10M+): Custom solution focus"
        ]
      },
      {
        subtitle: "Track Assignment",
        points: [
          "Automated segment classification",
          "Welcome sequence initialization",
          "Custom content track mapping",
          "Dynamic journey planning"
        ]
      }
    ]
  },
  content: {
    title: "Content Delivery System",
    icon: Mail,
    description: "Automated track-based content delivery",
    content: [
      {
        subtitle: "Email Sequence Configuration",
        points: [
          "Track-specific content paths",
          "Dynamic content personalization",
          "Industry-specific messaging",
          "Progressive complexity scaling"
        ]
      },
      {
        subtitle: "Content Customization",
        points: [
          "Industry vertical alignment",
          "Company size-based messaging",
          "Status considerations",
          "Behavioral adaptations"
        ]
      },
      {
        subtitle: "Resource Distribution",
        points: [
          "Personalized recommendations",
          "Success story matching",
          "Tool suggestions",
          "Implementation guides"
        ]
      }
    ],
    metrics: [
      { value: "45%", label: "Avg. Open Rate" },
      { value: "28%", label: "Click-Through" },
      { value: "3x", label: "Engagement Lift" }
    ]
  },
  monitoring: {
    title: "Engagement Monitoring",
    icon: BarChart2,
    description: "Real-time engagement tracking and analysis",
    content: [
      {
        subtitle: "Tracking Metrics",
        points: [
          "Email engagement analytics",
          "Resource download tracking",
          "Website behavior monitoring",
          "Tool usage statistics"
        ]
      },
      {
        subtitle: "Score Management",
        points: [
          "Real-time score updates",
          "Engagement velocity tracking",
          "Behavioral pattern analysis",
          "Interaction weighting"
        ]
      },
      {
        subtitle: "Track Adjustments",
        points: [
          "Engagement-based routing",
          "Content pace optimization",
          "Interest area focus",
          "Depth adjustment"
        ]
      }
    ],
    metrics: [
      { value: "Real-time", label: "Updates" },
      { value: "98%", label: "Track Accuracy" },
      { value: "<2min", label: "Response Time" }
    ]
  },
  triggers: {
    title: "Action Triggers",
    icon: Target,
    description: "Engagement-based workflow automation",
    content: [
      {
        subtitle: "High Engagement (75-100)",
        points: [
          "Immediate sales team notification",
          "Priority demo scheduling",
          "Account executive assignment",
          "Custom solution preparation"
        ]
      },
      {
        subtitle: "Medium Engagement (40-74)",
        points: [
          "Continued nurture sequence",
          "Incremental content delivery",
          "Targeted resource sharing",
          "Engagement acceleration"
        ]
      },
      {
        subtitle: "Low Engagement (0-39)",
        points: [
          "Re-engagement campaign trigger",
          "Alternative channel activation",
          "Content strategy adjustment",
          "Value proposition refresh"
        ]
      }
    ],
    metrics: [
      { value: "15min", label: "Response Time" },
      { value: "92%", label: "Action Rate" },
      { value: "85%", label: "Success Rate" }
    ]
  },
  demo: {
    title: "Demo Process",
    icon: Video,
    description: "Automated demo preparation and execution",
    content: [
      {
        subtitle: "Pre-Demo Process",
        points: [
          "Calendly scheduling integration",
          "Custom agenda generation",
          "Environment preparation",
          "Stakeholder confirmation"
        ]
      },
      {
        subtitle: "Demo Execution",
        points: [
          "Track-specific demo flow",
          "Interactive feature showcase",
          "Custom use case presentation",
          "ROI calculation"
        ]
      },
      {
        subtitle: "Follow-up Sequence",
        points: [
          "Immediate recap delivery",
          "Custom proposal generation",
          "Next steps documentation",
          "Success planning"
        ]
      }
    ],
    metrics: [
      { value: "95%", label: "Show Rate" },
      { value: "88%", label: "Satisfaction" },
      { value: "72%", label: "Conversion" }
    ]
  },
  integration: {
    title: "Integration Points",
    icon: Settings,
    description: "System integrations and data flow management",
    content: [
      {
        subtitle: "CRM Integration",
        points: [
          "Bi-directional Salesforce/HubSpot sync",
          "Activity logging automation",
          "Lead status management",
          "Task creation and assignment"
        ]
      },
      {
        subtitle: "Marketing Automation",
        points: [
          "Email platform synchronization",
          "Campaign performance tracking",
          "Content engagement metrics",
          "Behavioral data analysis"
        ]
      },
      {
        subtitle: "Analytics & Reporting",
        points: [
          "Real-time performance dashboards",
          "Conversion tracking",
          "ROI calculation",
          "Trend analysis"
        ]
      }
    ],
    metrics: [
      { value: "99.9%", label: "Uptime" },
      { value: "<5min", label: "Sync Time" },
      { value: "100%", label: "Data Accuracy" }
    ]
  }
};

function LeadNurturing() {
  const [currentStep, setCurrentStep] = useState('acquisition');
  
  const steps = [
    { id: 'acquisition', name: 'Lead Acquisition', icon: FileText },
    { id: 'processing', name: 'Initial Processing', icon: Activity },
    { id: 'segmentation', name: 'Segmentation Process', icon: Users },
    { id: 'content', name: 'Content Delivery', icon: Mail },
    { id: 'monitoring', name: 'Engagement Monitoring', icon: BarChart2 },
    { id: 'triggers', name: 'Action Triggers', icon: Target },
    { id: 'demo', name: 'Demo Process', icon: Video },
    { id: 'integration', name: 'Integration Points', icon: Settings }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50">
      <div className="max-w-7xl mx-auto px-4 py-12">
        <motion.div 
          className="text-center mb-16"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <h1 className="text-4xl font-bold mb-4">
            Lead Nurturing Workflow
          </h1>
          <p className="text-gray-600 max-w-2xl mx-auto">
            A comprehensive system for capturing, nurturing, and converting leads
          </p>
        </motion.div>

        <div className="grid grid-cols-2 gap-8">
          {/* Process Steps */}
          <div className="space-y-4">
            {steps.map((step) => (
              <ProcessStep
                key={step.id}
                step={step.name}
                icon={step.icon}
                isActive={currentStep === step.id}
                onClick={() => setCurrentStep(step.id)}
                description={workflowSteps[step.id]?.description}
              />
            ))}
          </div>

          {/* Step Details */}
          <div className="sticky top-4">
            <StepDetails step={currentStep} />
          </div>
        </div>

        {/* Automation Rules */}
        <motion.div
          className="mt-16 bg-white p-8 rounded-xl shadow-lg border border-gray-100"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          <h2 className="text-2xl font-bold mb-8">Automation Rules</h2>
          <div className="grid grid-cols-3 gap-8">
            <div className="space-y-4">
              <h3 className="text-lg font-semibold text-blue-600 flex items-center gap-2">
                <Zap className="w-5 h-5" />
                Score Updates
              </h3>
              <ul className="space-y-3">
                {[
                  { action: 'Email open', points: 1 },
                  { action: 'Content download', points: 3 },
                  { action: 'Demo request', points: 10 },
                  { action: 'Website visit', points: 2 },
                  { action: 'Tool usage', points: 5 }
                ].map((item, index) => (
                  <li key={index} className="flex justify-between items-center bg-gray-50 p-2 rounded">
                    <span>{item.action}</span>
                    <span className="font-semibold text-blue-600">+{item.points} pts</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="space-y-4">
              <h3 className="text-lg font-semibold text-blue-600 flex items-center gap-2">
                <RefreshCcw className="w-5 h-5" />
                Track Adjustments
              </h3>
              <ul className="space-y-3">
                {[
                  "Auto-upgrade to enterprise track",
                  "Auto-switch to existing customer",
                  "Auto-accelerate high engagement"
                ].map((rule, index) => (
                  <li key={index} className="flex items-center gap-2 bg-gray-50 p-2 rounded">
                    <CheckCircle className="w-5 h-5 text-green-500" />
                    <span>{rule}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="space-y-4">
              <h3 className="text-lg font-semibold text-blue-600 flex items-center gap-2">
                <AlertCircle className="w-5 h-5" />
                Alert Triggers
              </h3>
              <ul className="space-y-3">
                {[
                  "Score threshold reached",
                  "14-day inactivity alert",
                  "Demo request notification",
                  "High-value prospect alert"
                ].map((alert, index) => (
                  <li key={index} className="flex items-center gap-2 bg-gray-50 p-2 rounded">
                    <Target className="w-5 h-5 text-red-500" />
                    <span>{alert}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default LeadNurturing;
