import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, ThemeContext } from './contexts/ThemeContext';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import ServicesPage from './pages/ServicesPage';
import AgentsMarketplace from './pages/AgentsMarketplace';
import AboutUs from './pages/AboutUs';
import ContactPage from './pages/ContactPage';
import AIResumeAgent from './pages/AIResumeAgent';
import NewAgentPage from './pages/NewAgentPage';
import Documentation from './pages/Documentation';
import DAS140Agent from './pages/DAS140Agent';
import ProcessDiscoveryPage from './pages/ProcessDiscovery';
import AIIntegration from './pages/AIIntegration';
import ComplianceAutomation from './pages/ComplianceAutomation';
import OngoingSupport from './pages/OngoingSupport';

// Import new service pages
import ProjectManagement from './pages/services/ProjectManagement';
import FinancialManagement from './pages/services/FinancialManagement';
import ResourceAllocation from './pages/services/ResourceAllocation';
import QualityControl from './pages/services/QualityControl';
import SafetyMonitoring from './pages/services/SafetyMonitoring';
import PredictiveMaintenance from './pages/services/PredictiveMaintenance';
import LeadNurturing from './pages/services/LeadNurturing';

function AppContent() {
  const { isDarkMode, currentStyle } = useContext(ThemeContext);
  const isConstructFuture = currentStyle === 'Construct Future';

  return (
    <Router>
      <div className={`App flex flex-col min-h-screen ${isConstructFuture ? 'bg-construct-light dark:bg-construct-dark text-construct-dark dark:text-white' : 'bg-gradient-to-br from-blue-200 via-purple-200 to-pink-200 dark:from-blue-900 dark:via-purple-900 dark:to-pink-900'}`}>
        <Header />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/agents" element={<AgentsMarketplace />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/ai-resume-agent" element={<AIResumeAgent />} />
            <Route path="/new-agent" element={<NewAgentPage />} />
            <Route path="/documentation" element={<Documentation />} />
            <Route path="/das140-agent" element={<DAS140Agent />} />
            <Route path="/process-discovery" element={<ProcessDiscoveryPage />} />
            <Route path="/ai-integration" element={<AIIntegration />} />
            <Route path="/compliance-automation" element={<ComplianceAutomation />} />
            <Route path="/ongoing-support" element={<OngoingSupport />} />
            
            {/* New service routes */}
            <Route path="/services/project-management" element={<ProjectManagement />} />
            <Route path="/services/financial-management" element={<FinancialManagement />} />
            <Route path="/services/resource-allocation" element={<ResourceAllocation />} />
            <Route path="/services/quality-control" element={<QualityControl />} />
            <Route path="/services/safety-monitoring" element={<SafetyMonitoring />} />
            <Route path="/services/predictive-maintenance" element={<PredictiveMaintenance />} />
            <Route path="/services/lead-nurturing" element={<LeadNurturing />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

function App() {
  return (
    <ThemeProvider>
      <AppContent />
    </ThemeProvider>
  );
}

export default App;
